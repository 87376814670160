<template>
  <div>
    <iframe :src="frame" width="100%" height="1200px" frameborder="0"></iframe>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import { i18n } from '@/plugins/i18n'
import font from './fontKR.json'
import fontBold from './fontBoldKR.json'
import { formatPrice, sumdate, sumdatetime, currentCurrency } from '@/services/filters'
import 'jspdf-autotable'
import { api } from "@/services/api"

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(13)
    const fontBody = ref(11)
    const lastTable = ref(0)

    const shopData = ref({})
    const frame = ref('')
    onMounted(() => {
      shopData.value = JSON.parse(localStorage.getItem('shop'))
      console.log('shopData', shopData.value)
      api.get({
        path: '/printOrder',
        param: `?order_id_list=${ route.value.query.id }&lang=${ route.value.query.lang }&currency_id=${ localStorage.getItem('currencyId') }`,
      }).then(({ data }) => {
        savePDF(data)
      }).catch((err) => {
        console.log(err)
      })
    })

    const
        headers = [
          `월`,
          '일',
          '품 목',
          '규 격',
          '수 량',
          '단 가',
          '공급가액',
          '세 액',
          '비 고',
        ]

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
      })
      doc.setProperties({
        title: `거 래 명 세 서 (공급자용)`,
      })
      doc.addFileToVFS('-23-normal.ttf', fonts.value.data)
      doc.addFont('-23-normal.ttf', '-23', 'normal')
      doc.addFileToVFS('-23-bold.ttf', fontsBold.value.data)
      doc.addFont('-23-bold.ttf', '-23', 'bold')
      doc.setFont('-23', 'bold')
      for (let i = 0; i < data.length; i++) {
        doc.autoTable({
          theme: 'grid',
          styles: {
            font: '-23',
            fontStyle: 'bold',
            textColor: '#212121',
            fontSize: fontBody.value,
            lineColor: '#212121',
            halign: 'left',
          },
          showFoot: 'lastPage',
          headStyles: {
            fillColor: '#fff',
            halign: 'center',
          },
          columnStyles: {
            0: { halign: 'center', cellWidth: 12 },
            1: { halign: 'center', cellWidth: 10 },
            2: { halign: 'left', },
            3: { halign: 'center', cellWidth: 20 },
            4: { halign: 'right', cellWidth: 15 },
            5: { halign: 'right', cellWidth: 25 },
            6: { halign: 'right', cellWidth: 22 },
            7: { halign: 'right', cellWidth: 15 },
            8: { halign: 'right', cellWidth: 22 },
          },
          startY: 58,
          margin: {
            top: 58,
            left: 5,
            right: 5,
            bottom: 30,
          },
          footStyles: {
            textColor: '#212121',
            fillColor: '#fff',
            fontStyle: 'bold',
            lineWidth: 0.1,
            fontSize: fontBody.value,
            halign: 'right',
          },
          head: [headers],
          body: data[i].order_detail_vat_arr,
          didDrawPage(datax) {
            doc.setLineWidth(0.1)
            doc.setDrawColor('#212121')
            const currentY = ref(10)
            const spacer = ref(8)
            let myY = 0
            const currentX = ref(5)
            doc.line(5, currentY.value, 205, currentY.value)
            currentY.value = currentY.value + spacer.value
            doc.line(5, currentY.value, 50, currentY.value)
            currentY.value = currentY.value + spacer.value
            doc.line(5, currentY.value, 205, currentY.value)
            currentY.value = currentY.value + spacer.value
            doc.line(currentX.value + 10, currentY.value, 100, currentY.value)
            doc.line(110, currentY.value, 205, currentY.value)
            currentY.value = currentY.value + spacer.value
            doc.line(currentX.value + 10, currentY.value, 100, currentY.value)
            doc.line(110, currentY.value, 205, currentY.value)
            currentY.value = currentY.value + spacer.value
            doc.line(currentX.value + 10, currentY.value, 100, currentY.value)
            doc.line(110, currentY.value, 205, currentY.value)
            currentY.value = currentY.value + spacer.value
            doc.line(5, currentY.value, 205, currentY.value)
            currentY.value = datax.cursor.y
            doc.line(5, currentY.value, 205, currentY.value)
            currentY.value = currentY.value + spacer.value
            currentY.value = currentY.value + spacer.value
            doc.line(5, currentY.value, 205, currentY.value)
            currentY.value = currentY.value + spacer.value
            doc.line(5, currentY.value, 205, currentY.value)
            currentY.value = currentY.value + spacer.value
            doc.line(5, currentY.value, 205, currentY.value)
            /// Verical
            doc.line(50, 10, 50, 26)
            doc.line(currentX.value, 10, currentX.value, currentY.value)
            currentX.value = currentX.value + 10
            doc.line(currentX.value, 26, currentX.value, spacer.value + 9 * 5.55)
            currentX.value = currentX.value + 10
            doc.line(currentX.value + 10, 26, currentX.value + 10, spacer.value + 9 * 5.55)
            currentX.value = 100
            doc.line(currentX.value, 26, currentX.value, spacer.value + 9 * 5.55)
            currentX.value = currentX.value + 10
            doc.line(currentX.value, 26, currentX.value, spacer.value + 9 * 5.55)
            currentX.value = currentX.value + 10
            doc.line(currentX.value + 10, 26, currentX.value + 10, spacer.value + 9 * 5.55)
            doc.line(205, 10, 205, currentY.value)
            doc.line(60, 42, 60, 50)
            doc.line(70, 42, 70, 50)

            currentX.value = currentX.value + 10
            let mySpacer = 7
            for (let i = 0; i < 11; i++) {
              doc.line(currentX.value + mySpacer, 26, currentX.value + mySpacer, spacer.value + 8 * 3.25)
              mySpacer += 6.2
            }
            currentX.value = currentX.value + 21.8
            doc.line(currentX.value + 16.2, 26 + 8, currentX.value + 16.2, spacer.value + 9 * 3.8)
            currentX.value = currentX.value + 12.4
            doc.line(currentX.value + 16.2, 26 + 8, currentX.value + 16.2, spacer.value + 9 * 3.8)
            currentX.value = 162
            doc.line(currentX.value, 50, currentX.value, 58)
            currentX.value = 174
            doc.line(currentX.value, 50, currentX.value, 58)
            currentX.value = 55
            myY = datax.cursor.y
            doc.line(20, myY, 20, myY + 16)
            doc.line(50, myY, 50, myY + 16)
            doc.line(60, myY, 60, myY + 16)
            doc.line(80, myY, 80, myY + 16)
            doc.line(95, myY, 95, myY + 16)
            doc.line(125, myY, 125, myY + 16)
            doc.line(143, myY, 143, myY + 16)
            doc.line(143 + 20, myY, 143 + 20, myY + 16)
            doc.line(143 + 36, myY, 143 + 36, myY + 16)

            doc.setFont('-23', 'normal')
            doc.setFontSize(fontBody.value)
            let mypositiony = 15.5
            let myspacer = 8
            let mypositionx = 26
            doc.text(`거 래 일 자`, mypositionx, mypositiony, null, null, 'center')
            doc.setFont('-23', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(`거  래  명  세  서 (공급자용)`, 130, mypositiony, null, null, 'center')
            mypositiony += myspacer
            doc.setFont('-23', 'normal')
            doc.setFontSize(fontBody.value)
            doc.text(`${ data[i].create_date_text }`, mypositionx, mypositiony, null, null, 'center')
            doc.setFont('-23', 'normal')
            doc.setFontSize(fontTitle.value)
            doc.text(`${ data[i].order_code }`, 130, mypositiony - 2, null, null, 'center')
            mypositiony += myspacer
            doc.setFontSize(fontBody.value)
            doc.text(`상 호`, 25, mypositiony, null, null, 'center')
            doc.text(`등록번호`, 120, mypositiony, null, null, 'center')
            let conntNumber = 134
            let arrx = shopData.value.shop_tax_number
            if (arrx.length <= 12) {
              for (let i = 0; i < arrx.length; i++) {
                doc.text(arrx[i], conntNumber, mypositiony, null, null, 'center')
                conntNumber += 6.2
              }
            }
            doc.text(data[i].customer_fname, 37, mypositiony, null, null, 'left')
            doc.text(`공`, 105, mypositiony + 5, null, null, 'center')
            doc.text(`공`, 10, mypositiony - 1, null, null, 'center')
            mypositiony += myspacer
            doc.text(`사 업 장`, 25, mypositiony, null, null, 'center')
            doc.setFontSize(12)
            doc.text(`성명`, 174, mypositiony, null, null, 'center')
            doc.setFontSize(7)
            doc.text(data[i].user_create, 182, mypositiony, null, null, 'left')
            doc.setFontSize(7)
            doc.text(`${ shopData.value.shop_name } (${ shopData.value.shop_product_type })`, 131, mypositiony, null, null, 'left')
            doc.setFontSize(7)
            doc.text(data[i].order_transportation_full_address, 37, mypositiony, null, null, 'left')
            doc.setFontSize(fontBody.value)
            doc.text(`상 호`, 120, mypositiony, null, null, 'center')
            doc.text(`급`, 105, mypositiony + 5, null, null, 'center')
            doc.text(`급`, 10, mypositiony - 2, null, null, 'center')
            mypositiony += myspacer
            doc.text(`전화번호`, 25, mypositiony, null, null, 'center')
            doc.text('Fax.', 66, mypositiony, null, null, 'center')
            doc.text(data[i].customer_fax, 72, mypositiony, null, null, 'left')
            doc.setFontSize(9)
            doc.text(data[i].order_transportation_phone, 37, mypositiony, null, null, 'left')
            doc.text(`사 업 장`, 120, mypositiony, null, null, 'center')
            doc.setFontSize(7)
            doc.text(shopData.value.shop_address_ko, 131, mypositiony, null, null, 'left')
            doc.setFontSize(fontBody.value)
            doc.text(`자`, 105, mypositiony + 5, null, null, 'center')
            doc.text(`받`, 10, mypositiony - 3.5, null, null, 'center')
            mypositiony += myspacer
            doc.text(`는`, 10, mypositiony - 5, null, null, 'center')
            doc.text(`자`, 10, mypositiony + 1, null, null, 'center')
            doc.setFontSize(11)
            doc.text(shopData.value.shop_phone, 131, mypositiony, null, null, 'left')
            doc.text('팩스', 168, mypositiony, null, null, 'center')
            doc.text(shopData.value.shop_fax_number, 175, mypositiony, null, null, 'left')
            doc.text(`전 화`, 120, mypositiony, null, null, 'center')
            doc.text(`합계금액`, 25, mypositiony, null, null, 'center')
            doc.setFont('-23', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(`${ currentCurrency() } ${ formatPrice(data[i].sum_total_transportation) }`, 37, mypositiony, null, null, 'left')
            doc.setFont('-23', 'normal')
            doc.setFontSize(fontBody.value)

            let positionTextFooter = datax.cursor.y + 5.5
            doc.text(`공급\r  가액`, 12.5, positionTextFooter + 2, null, null, 'center')
            doc.text(formatPrice(String(+data[i].sum_total_transportation - +data[i].order_vat_value)), 35, positionTextFooter, null, null, 'center')
            doc.text('세액', 55, positionTextFooter + 4, null, null, 'center')
            doc.text(formatPrice(data[i].order_vat_value), 70, positionTextFooter + 4, null, null, 'center')
            doc.setFont('-23', 'bold')
            doc.text('합계\r금액', 87.5, positionTextFooter + 2, null, null, 'center')
            doc.text(formatPrice(data[i].sum_total_transportation), 111, positionTextFooter, null, null, 'center')
            doc.setFont('-23', 'normal')
            doc.text('미수금', 134, positionTextFooter + 4, null, null, 'center')
            doc.text('-', 153, positionTextFooter + 4, null, null, 'center')
            doc.text('인수자', 154 + 16.5, positionTextFooter + 4, null, null, 'center')
            doc.text('', 162 + 17 * 2, positionTextFooter + 4, null, null, 'center')
            positionTextFooter += myspacer
            positionTextFooter += myspacer
            doc.text(`${ data[i].bank_number || '' } ${ data[i].bank_full_name || '' } ${ data[i].bank_name || '' }`, 7, positionTextFooter, null, null, 'left')
            positionTextFooter += myspacer
            doc.text(`${ data[i].order_transportation_note || '' }`, 7, positionTextFooter, null, null, 'left')

          }
        })
        doc.addPage(1)
      }
      doc.deletePage(data.length + 1)

      frame.value = doc.output('datauristring', { filename: `INVOICE.pdf` })
    }

    return { savePDF, frame }
  },
}
</script>
